// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.app {
    max-width: 1300px;
    margin: 0 auto;
}

h2 {
    padding: 0;
    margin: 0;
    font-weight: bolder;
    font-size: 1rem;
    background: -webkit-linear-gradient(left, #0095db, #b8d407);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":";AACA;IACI,iBAAiB;IACjB,cAAc;AAClB;;AAEA;IACI,UAAU;IACV,SAAS;IACT,mBAAmB;IACnB,eAAe;IACf,2DAA2D;IAC3D,6BAA6B;IAC7B,oCAAoC;AACxC","sourcesContent":["\n.app {\n    max-width: 1300px;\n    margin: 0 auto;\n}\n\nh2 {\n    padding: 0;\n    margin: 0;\n    font-weight: bolder;\n    font-size: 1rem;\n    background: -webkit-linear-gradient(left, #0095db, #b8d407);\n    -webkit-background-clip: text;\n    -webkit-text-fill-color: transparent;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
