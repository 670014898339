// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
    position: relative;
    height: auto;
    text-align: left;
}

.bottom-right {
    position: absolute;
    bottom: 10px;
    right: 10px;
}

img.bgImg {
    padding-top: 80px;
    width: calc(100% - 270px);
}
`, "",{"version":3,"sources":["webpack://./src/components/InputComponent.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,YAAY;IACZ,gBAAgB;AACpB;;AAEA;IACI,kBAAkB;IAClB,YAAY;IACZ,WAAW;AACf;;AAEA;IACI,iBAAiB;IAEjB,yBAAyB;AAC7B","sourcesContent":[".container {\n    position: relative;\n    height: auto;\n    text-align: left;\n}\n\n.bottom-right {\n    position: absolute;\n    bottom: 10px;\n    right: 10px;\n}\n\nimg.bgImg {\n    padding-top: 80px;\n    width: -webkit-calc(100% - 270px);\n    width: calc(100% - 270px);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
