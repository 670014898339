// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.resultFOV {
    font-weight: bolder;
    font-size: 3rem;
    color: #0094DA;
}

.resultFOV::after {
    content: '°';
}
`, "",{"version":3,"sources":["webpack://./src/components/ResultComponent.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,eAAe;IACf,cAAc;AAClB;;AAEA;IACI,YAAY;AAChB","sourcesContent":[".resultFOV {\n    font-weight: bolder;\n    font-size: 3rem;\n    color: #0094DA;\n}\n\n.resultFOV::after {\n    content: '°';\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
