import Frustum from "../models/Frustum";

export default class CameraStore {

    cameras: any[] =  [{
      kit: "wide-angle",
      name: "56° Wide-Angle Kit",
      partNo: "CK_W56",
      sensor: {
        size: "1/1.8''",
        pixel: "2.40µm",
        resolution: "3088 x 2046 @18 fps",
        mpClass: "6 MPixel"
      },
      fixedFocalLength: true,
      lens: [{
        length: 6.00,
        horizontalFov: 56.8,
        verticalFov: 44.0,
      }],
      multicam: "Yes",
      interface: {
        cable: "Cat5e/RJ45",
        bandwidth: "1 GB/s"
      }
	}, {
      kit: "wide-angle",
      name: "76° Wide-Angle Kit",
      partNo: "CK_W76",
      sensor: {
        size: "1/1.8''",
        pixel: "2.40 µm",
        resolution: "3088 x 2046 @ 18fps",
        mpClass: "6 MPixel"
      },
      fixedFocalLength: true,
      lens: [{
        length: 4.0,
        horizontalFov: 76.0,
        verticalFov: 60.0,
      }],
      multicam: "Yes",
      interface: {
        cable: "Cat5e/RJ45",
        bandwidth: "1 GB/s"
      }
    }, {
      kit: "wide-angle",
      name: "89° Wide-Angle Kit",
      partNo: "CK_W89",
      sensor: {
        size: "1/1.8''",
        pixel: "2.40 µm",
        resolution: "3088 x 2046 @ 18fps",
        mpClass: "6 MPixel"
      },
      fixedFocalLength: true,
      lens: [{
        length: 3.50,
        horizontalFov: 89.0,
        verticalFov: 73.8,
      }],
      multicam: "Yes",
      interface: {
        cable: "Cat5e/RJ45",
        bandwidth: "1 GB/s"
      }
    }, {
      kit: "wide-angle",
      name: "95° HQ Wide-Angle Kit",
      partNo: "CK_WQ95",
      sensor: {
        size: "1.1''",
        pixel: "3.45 µm",
        resolution: "4096 x 3000 @ 10fps",
        mpClass: "12.3 MPixel"
      },
      fixedFocalLength: true,
      lens: [{
        length: 6.00,
        horizontalFov: 95.7,
        verticalFov: 78.7,
      }],
      multicam: "Yes",
      interface: {
        cable: "Cat5e/RJ45",
        bandwidth: "1 GB/s"
      }
	}, {
      kit: "wide-angle",
      name: "100° Wide-Angle Kit",
      partNo: "CK_W100",
      sensor: {
        size: "2/3''",
        pixel: "3.45 µm",
        resolution: "2448 x 2048 @ 23fps",
        mpClass: "5 MPixel"
      },
      fixedFocalLength: true,
      lens: [{
        length: 3.70,
        horizontalFov: 100.2,
        verticalFov: 83.7,
      }],
      multicam: "Yes",
      interface: {
        cable: "Cat5e/RJ45",
        bandwidth: "1 GB/s"
      }
    },{
      kit: "ultrawide-angle",
      name: "120° Ultra Wide-Angle Kit",
      partNo: "CK_WU110",
      sensor: {
        size: "1/2.5''",
        pixel: "2.2 µm",
        resolution: "2592 x 1944 @ 25fps",
        mpClass: "5.0 MPixel"
      },
      fixedFocalLength: true,
      lens: [{
        length: 1.80,
        horizontalFov: 120.0,
        verticalFov: 104.0,
      }],
      multicam: "No",
      interface: {
        cable: "Cat5e/RJ45",
        bandwidth: "1 GB/s"
      }
    },{
      kit: "ultrawide-angle",
      name: "135° Ultra Wide-Angle Kit",
      partNo: "CK_WU125",
      sensor: {
        size: "1/2.5''",
        pixel: "2.2 µm",
        resolution: "2592 x 1944 @25fps",
        mpClass: "5.0 MPixel"
      },
      fixedFocalLength: true,
      lens: [{
        length: 1.30,
        horizontalFov: 135.0,
        verticalFov: 119.0,
      }],
      multicam: "No",
      interface: { 
        cable: "Cat5e/RJ45",
        bandwidth: "1 GB/s"
      }
	},{
      kit: "tele",
      name: "44° Tele Kit",
      partNo: "CK_T44",
      sensor: {
        size: "1/1.8''",
        pixel: "2.40 µm",
        resolution: "3088 x 2046 @ 18fps",
        mpClass: "6 MPixel"
      },
      fixedFocalLength: true,
      lens: [{
        length: 8.00,
        horizontalFov: 44.7,
        verticalFov: 33.9,
      }],
      multicam: "No",
      interface: { 
        cable: "Cat5e/RJ45",
        bandwidth: "1 GB/s"
      }
	},{
      kit: "tele",
      name: "29° Tele Kit",
      partNo: "CK_T29",
      sensor: {
        size: "1/1.8''",
        pixel: "2.40 µm",
        resolution: "3088 x 2046 @ 18fps",
        mpClass: "6 MPixel"
      },
      fixedFocalLength: true,
      lens: [{
        length: 12.00,
        horizontalFov: 29.9,
        verticalFov: 21.9,
      }],
      multicam: "No",
      interface: { 
        cable: "Cat5e/RJ45",
        bandwidth: "1 GB/s"
      }
    },{
      kit: "super-hq",
      name: "Super HQ Kit",
      partNo: "CK_QXX",
      sensor: {
        size: "1.1''",
        pixel: "3.45 µm",
        resolution: "4096 x 3000 @ 10 fps",
        mpClass: "12.29 MPixel"
      },
      fixedFocalLength: true,
      lens: [{
        length: 8.00,
        horizontalFov: 79.2,
        verticalFov: 63.8,
      },{
        length: 12.00,
        horizontalFov: 60.0,
        verticalFov: 46.9,
      },{
        length: 16.00,
        horizontalFov: 48.2,
        verticalFov: 36.7,
      },{
        length: 25.00,
        horizontalFov: 31.5,
        verticalFov: 23.9,
      },{
        length: 35.00,
        horizontalFov: 22.1,
        verticalFov: 16.7,
      },{
        length: 50.00,
        horizontalFov: 16.1,
        verticalFov: 12.1,
      }],
      multicam: "Yes",
      interface: { 
        cable: "Cat5e/RJ45",
        bandwidth: "1 GB/s"
      }
	  /*
    },{
      kit: "low-cost",
      name: "Varifocal Kit",
      partNo: "CK/S/m",
      sensor: {
        size: "1/2''",
        pixel: "2.2 µm",
        resolution: "2560 x 1920 @ 15 fps",
        mpClass: "1.92 MPixel"
      },
      fixedFocalLength: false,
      minLens: {
        length: 3.8,
        horizontalFov: 28,
        verticalFov: 21,
      },
      maxLens: {
        length: 13,
        horizontalFov: 97,
        verticalFov: 71,
      },
      multicam: "No",
      interface: {
        cable: "Cat5e/RJ45",
        bandwidth: "1 GB/s"
      }
	*/
    },{
      kit: "low-cost",
      name: "70° USB2 Webcam",
      partNo: "LGT_C920",
      sensor: {
        size: "",
        pixel: "",
        resolution: "1920 x 1080 @ 30 fps",
        mpClass: ""
      },
      fixedFocalLength: true,
      lens: [{
        length: -1,
        horizontalFov: 70,
        verticalFov: 40,
      }],
      multicam: "No",
      interface: {
        cable: "USB 2.0",
        bandwidth: ""
      }
    },{
      kit: "low-cost",
      name: "78° USB2 Webcam",
      partNo: "LGT_C930",
      sensor: {
        size: "",
        pixel: "",
        resolution: "1920 x 1080 @ 30 fps",
        mpClass: ""
      },
      fixedFocalLength: true,
      lens: [{
        length: -1,
        horizontalFov: 78,
        verticalFov: 48,
      }],
      multicam: "No",
      interface: {
        cable: "USB 2.0",
        bandwidth: ""
      }
    }
    ];

    getAll(): any[] {
      return this.cameras;
    }

    getBy(frustum: Frustum): any[] {
      let fittingCameras = this.cameras.map(camera => {

        if (camera.fixedFocalLength) {
          return {
            ...camera, lens: camera.lens.filter((lens: any) => frustum.containsLens({horizontalFov: lens.horizontalFov, verticalFov: lens.verticalFov}))
          }
        } else {
          return frustum.containsLens(camera.maxLens) ? camera : null;
        }

      }).filter(camera => camera != null).filter(camera => camera.fixedFocalLength ? camera.lens.length > 0 : true);


      return fittingCameras;
    }

}
