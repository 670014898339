// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.top-center {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0);
}

.right-center {
    position: absolute; 
    top: 33%;
    right: 0;
}

.center {
    position: absolute;
    top: 33%;
    left: 50%;
}
`, "",{"version":3,"sources":["webpack://./src/components/InputFormComponent.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,MAAM;IACN,SAAS;IACT,6BAA6B;AACjC;;AAEA;IACI,kBAAkB;IAClB,QAAQ;IACR,QAAQ;AACZ;;AAEA;IACI,kBAAkB;IAClB,QAAQ;IACR,SAAS;AACb","sourcesContent":[".top-center {\n    position: absolute;\n    top: 0;\n    left: 50%;\n    transform: translate(-50%, 0);\n}\n\n.right-center {\n    position: absolute; \n    top: 33%;\n    right: 0;\n}\n\n.center {\n    position: absolute;\n    top: 33%;\n    left: 50%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
